import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const UnisatWallet = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#12100F' height='48' rx='8' width='48' />
    <g clipPath='url(#clip0_1422_1150)'>
      <path
        d='M29.3395 10.4694L36.1466 17.1766C36.726 17.7464 37.0112 18.3228 37.0022 18.9037C36.9933 19.4846 36.744 20.0141 36.2566 20.4945C35.7468 20.9972 35.1966 21.2519 34.6082 21.2631C34.0198 21.272 33.4359 20.9905 32.8564 20.4208L25.8943 13.5616C25.1038 12.7818 24.3402 12.23 23.6058 11.906C22.8715 11.582 22.0989 11.5306 21.2904 11.7541C20.4796 11.9753 19.6105 12.545 18.6785 13.461C17.3939 14.7279 16.7808 15.9165 16.8436 17.0269C16.9065 18.1373 17.5443 19.2902 18.7548 20.4811L25.7731 27.3984C26.3592 27.9748 26.6467 28.5512 26.6377 29.1232C26.6287 29.6974 26.3772 30.2269 25.8809 30.7162C25.3868 31.2033 24.8411 31.458 24.2482 31.4781C23.6553 31.4982 23.0646 31.2189 22.4807 30.6425L15.6736 23.9353C14.5664 22.8449 13.7669 21.8127 13.275 20.8386C12.7832 19.8644 12.599 18.7629 12.727 17.5341C12.8416 16.4818 13.1807 15.4629 13.7466 14.4754C14.3103 13.4879 15.1188 12.478 16.1676 11.4435C17.4163 10.2124 18.6089 9.26958 19.7453 8.6127C20.8794 7.95583 21.9776 7.59165 23.0376 7.51568C24.0999 7.43972 25.1465 7.64974 26.1818 8.14574C27.2171 8.64175 28.2682 9.4148 29.3372 10.4694H29.3395Z'
        fill='url(#paint0_linear_1422_1150)'
      />
      <path
        d='M18.6606 37.5329L11.8557 30.8257C11.2763 30.2537 10.991 29.6795 11 29.0986C11.009 28.5177 11.2583 27.9882 11.7456 27.5078C12.2554 27.0051 12.8057 26.7504 13.3941 26.7392C13.9825 26.7303 14.5664 27.0096 15.1458 27.5815L22.1057 34.4407C22.8984 35.2205 23.6598 35.7723 24.3942 36.0963C25.1286 36.4203 25.9011 36.4694 26.7119 36.2482C27.5226 36.027 28.3917 35.4573 29.3238 34.539C30.6084 33.2722 31.2215 32.0836 31.1586 30.9731C31.0957 29.8627 30.4579 28.7098 29.2474 27.5167L25.5081 23.8637C24.9219 23.2873 24.6345 22.7109 24.6435 22.1389C24.6524 21.5647 24.904 21.0352 25.4003 20.5459C25.8944 20.0588 26.4401 19.8041 27.033 19.784C27.6259 19.7639 28.2166 20.0431 28.8005 20.6196L32.3264 24.0626C33.4336 25.1529 34.2332 26.1851 34.725 27.1593C35.2168 28.1334 35.401 29.2349 35.273 30.4637C35.1584 31.5161 34.8193 32.5349 34.2534 33.5224C33.6897 34.51 32.8812 35.5199 31.8324 36.5543C30.5837 37.7854 29.3911 38.7283 28.2548 39.3851C27.1184 40.042 26.0201 40.4084 24.9579 40.4844C23.8956 40.5603 22.849 40.3503 21.8137 39.8543C20.7784 39.3583 19.7273 38.5853 18.6583 37.5307L18.6606 37.5329Z'
        fill='url(#paint1_linear_1422_1150)'
      />
      <path
        d='M22.9052 20.2264C24.2857 20.2264 25.4048 19.113 25.4048 17.7397C25.4048 16.3663 24.2857 15.2529 22.9052 15.2529C21.5247 15.2529 20.4056 16.3663 20.4056 17.7397C20.4056 19.113 21.5247 20.2264 22.9052 20.2264Z'
        fill='url(#paint2_radial_1422_1150)'
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint0_linear_1422_1150'
        x1='35.027'
        x2='13.9675'
        y1='14.3771'
        y2='23.7845'
      >
        <stop stopColor='#201C1B' />
        <stop offset='0.36' stopColor='#77390D' />
        <stop offset='0.67' stopColor='#EA8101' />
        <stop offset='1' stopColor='#F4B852' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint1_linear_1422_1150'
        x1='13.5561'
        x2='37.9814'
        y1='33.9016'
        y2='26.5945'
      >
        <stop stopColor='#1F1D1C' />
        <stop offset='0.37' stopColor='#77390D' />
        <stop offset='0.67' stopColor='#EA8101' />
        <stop offset='1' stopColor='#F4FB52' />
      </linearGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(22.9052 17.7419) scale(2.49961 2.48673)'
        gradientUnits='userSpaceOnUse'
        id='paint2_radial_1422_1150'
        r='1'
      >
        <stop stopColor='#F4B852' />
        <stop offset='0.33' stopColor='#EA8101' />
        <stop offset='0.64' stopColor='#77390D' />
        <stop offset='1' stopColor='#211C1D' />
      </radialGradient>
      <clipPath id='clip0_1422_1150'>
        <rect fill='white' height='33' transform='translate(11 7.5)' width='26' />
      </clipPath>
    </defs>
  </Icon>
));

UnisatWallet.displayName = 'UnisatWallet';

export { UnisatWallet };
