import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const BTC = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon {...props} ref={ref} fill='none' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
    <title>BTC</title>
    <path
      d='M59.096 37.257C55.089 53.33 38.81 63.11 22.738 59.102 6.67 55.095-3.11 38.816.898 22.746 4.903 6.673 21.181-3.11 37.25.898c16.072 4.006 25.852 20.287 21.845 36.36Z'
      fill='#F7931A'
    />
    <path
      d='M43.225 25.726c.597-3.992-2.442-6.139-6.599-7.57l1.349-5.408-3.292-.82-1.313 5.265c-.865-.216-1.754-.42-2.637-.62l1.322-5.301-3.29-.82-1.35 5.406c-.715-.163-1.419-.324-2.101-.494l.003-.017-4.54-1.134-.875 3.516s2.442.56 2.391.595c1.333.332 1.574 1.215 1.534 1.914L22.291 26.4c.092.023.211.057.343.11-.11-.027-.227-.057-.349-.086l-2.152 8.63c-.163.406-.577 1.013-1.509.783.033.047-2.392-.597-2.392-.597l-1.635 3.768 4.284 1.068c.797.2 1.578.409 2.347.605l-1.362 5.47 3.288.82 1.35-5.411c.898.244 1.77.469 2.623.68l-1.345 5.387 3.292.82 1.362-5.46c5.614 1.063 9.835.635 11.612-4.443 1.431-4.088-.072-6.446-3.025-7.983 2.151-.496 3.771-1.911 4.203-4.834Zm-7.521 10.547c-1.018 4.088-7.9 1.878-10.132 1.324l1.808-7.247c2.231.557 9.387 1.66 8.324 5.923Zm1.018-10.606c-.928 3.718-6.657 1.829-8.515 1.366l1.639-6.573c1.858.463 7.843 1.328 6.876 5.207Z'
      fill='#fff'
    />
  </Icon>
));

BTC.displayName = 'BTC';

export { BTC };
