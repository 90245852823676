import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const Optimism = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#FF0420' height='128' rx='64' width='128' />
    <path
      d='M38.996 85.316c-4.7 0-8.55-1.106-11.553-3.316C24.482 79.75 23 76.553 23 72.408c0-.868.099-1.934.296-3.198.514-2.841 1.245-6.256 2.192-10.243 2.686-10.855 9.618-16.283 20.796-16.283 3.041 0 5.766.513 8.176 1.54 2.41.986 4.305 2.487 5.687 4.5 1.383 1.973 2.074 4.342 2.074 7.105 0 .829-.099 1.875-.296 3.138a133.998 133.998 0 0 1-2.133 10.243c-1.383 5.409-3.772 9.455-7.169 12.139-3.397 2.644-7.939 3.967-13.627 3.967Zm.83-8.526c2.212 0 4.088-.652 5.628-1.954 1.58-1.303 2.706-3.296 3.377-5.98.909-3.711 1.6-6.948 2.074-9.711.158-.83.237-1.678.237-2.547 0-3.591-1.876-5.388-5.628-5.388-2.213 0-4.108.652-5.688 1.954-1.54 1.303-2.647 3.297-3.318 5.98-.71 2.645-1.422 5.883-2.133 9.711-.158.79-.237 1.619-.237 2.487 0 3.632 1.896 5.448 5.688 5.448ZM64.31 85.316c-.44 0-.781-.14-1.022-.418-.2-.319-.26-.676-.18-1.074l8.299-38.795c.08-.438.3-.796.661-1.075.361-.278.742-.417 1.143-.417h15.996c4.45 0 8.018.915 10.704 2.745 2.726 1.83 4.089 4.477 4.089 7.938 0 .995-.12 2.03-.361 3.104-1.002 4.576-3.026 7.958-6.073 10.146-3.007 2.189-7.137 3.283-12.388 3.283h-8.119l-2.766 13.07c-.08.438-.3.796-.661 1.075-.361.279-.742.418-1.143.418h-8.178Zm21.289-22.8c1.683 0 3.147-.457 4.39-1.372 1.282-.916 2.124-2.229 2.525-3.94.12-.676.18-1.273.18-1.79 0-1.154-.34-2.03-1.022-2.626-.681-.637-1.844-.955-3.488-.955h-7.216l-2.285 10.683h6.916Z'
      fill='#fff'
    />
  </Icon>
));

Optimism.displayName = 'Optimism';

export { Optimism };
