import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const WalletConnect = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#5E9BF3' height='48' rx='8' width='48' />
    <g clipPath='url(#clip0_1328_1075)'>
      <path
        d='M14.8464 18.2123C19.9018 13.2626 28.0983 13.2626 33.1537 18.2123L33.7621 18.808C34.015 19.0555 34.015 19.4567 33.7621 19.7042L31.6808 21.742C31.5545 21.8657 31.3495 21.8657 31.2232 21.742L30.3859 20.9222C26.8591 17.4692 21.141 17.4692 17.6142 20.9222L16.7175 21.8001C16.5912 21.9239 16.3862 21.9239 16.2599 21.8001L14.1785 19.7623C13.9258 19.5148 13.9258 19.1136 14.1785 18.8661L14.8464 18.2123ZM37.4581 22.4266L39.3105 24.2403C39.5632 24.4878 39.5632 24.889 39.3105 25.1365L30.9579 33.3143C30.7052 33.5619 30.2955 33.5619 30.0426 33.3143L24.1145 27.5103C24.0513 27.4484 23.9489 27.4484 23.8857 27.5103L17.9577 33.3143C17.7049 33.5619 17.2951 33.5619 17.0423 33.3143L8.68958 25.1364C8.43681 24.8889 8.43681 24.4876 8.68958 24.2402L10.542 22.4265C10.7947 22.179 11.2046 22.179 11.4573 22.4265L17.3855 28.2307C17.4487 28.2926 17.5512 28.2926 17.6144 28.2307L23.5422 22.4265C23.795 22.179 24.2048 22.179 24.4576 22.4265L30.3859 28.2307C30.449 28.2926 30.5515 28.2926 30.6147 28.2307L36.5428 22.4266C36.7954 22.1791 37.2054 22.1791 37.4581 22.4266Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1328_1075'>
        <rect fill='white' height='19' transform='translate(8.5 14.5)' width='31' />
      </clipPath>
    </defs>
  </Icon>
));

WalletConnect.displayName = 'WalletConnect';

export { WalletConnect };
