import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const Moonbeam = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='url(#paint0_radial_303_506)' height='128' rx='64' width='128' />
    <path
      d='M28.591 84.589a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.084zM94.618 74.519H42.955c-1.148 0-1.88 1.227-1.335 2.24l.026.05c.263.49.778.794 1.334.794h51.613c.556 0 1.071-.305 1.334-.795l.026-.049c.543-1.013-.187-2.24-1.335-2.24zM101.485 60.544H42.953c-.874 0-1.561.734-1.515 1.605v.05a1.517 1.517 0 001.52 1.427h58.527c.803 0 1.472-.627 1.516-1.428v-.049a1.519 1.519 0 00-1.512-1.605h-.004zM79.444 95.477H53.066c-1.603 0-2.108 2.155-.676 2.877.033.016.066.032.096.049.21.104.442.158.678.158h26.184a1.524 1.524 0 00.773-.207c1.43-.722.927-2.877-.675-2.877h-.002zM95.562 88.49H69.184c-1.603 0-2.108 2.156-.676 2.878.033.016.065.032.096.049.21.104.442.158.677.158h26.185a1.524 1.524 0 00.773-.207c1.43-.722.927-2.877-.675-2.877h-.002zM59.594 84.102l-.044-.049c-.904-.966-.212-2.55 1.113-2.55h42.813c1.325 0 2.017 1.581 1.113 2.55l-.044.05a1.53 1.53 0 01-1.113.484H60.705a1.53 1.53 0 01-1.113-.485h.002zM34.094 81.505h20.444c1.602 0 2.108 2.155.675 2.877-.032.016-.065.032-.095.049-.21.105-.443.158-.678.158H34.192a1.524 1.524 0 01-.773-.207c-1.43-.722-.927-2.877.675-2.877zM37.449 63.628a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.084zM75.319 69.49l.013-.05a1.517 1.517 0 00-1.467-1.91H31.048c-.997 0-1.728.948-1.467 1.91l.014.05c.181.663.78 1.125 1.467 1.125h42.79c.686 0 1.285-.462 1.466-1.126zM25.542 70.615a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.084zM37.449 77.6a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.085zM63.68 91.575a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.084zM47.562 98.561a1.542 1.542 0 100-3.084 1.542 1.542 0 000 3.084z'
      fill='#E1147B'
    />
    <path
      d='M72.5 29c-14.086 0-25.502 11.512-25.502 25.711v.095c.038.669.592 1.194 1.258 1.194h48.487c.666 0 1.22-.523 1.255-1.194v-.04c.002-.02 0-.036 0-.055C98 40.512 86.584 29 72.499 29z'
      fill='#53CBC8'
    />
    <defs>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='rotate(90 0 64) scale(85.5)'
        gradientUnits='userSpaceOnUse'
        id='paint0_radial_303_506'
        r='1'
      >
        <stop stopColor='#3F398D' />
        <stop offset='1' stopColor='#3F1262' />
      </radialGradient>
    </defs>
  </Icon>
));

Moonbeam.displayName = 'Moonbeam';

export { Moonbeam };
