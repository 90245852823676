import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const Polygon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#8247E5' height='128' rx='64' width='128' />
    <g clipPath='url(#a)'>
      <path
        d='M84.417 50.313c-1.459-.835-3.334-.835-5 0L67.75 57.21l-7.917 4.388-11.458 6.896c-1.458.835-3.333.835-5 0l-8.958-5.433c-1.459-.836-2.5-2.508-2.5-4.388V48.224c0-1.672.833-3.343 2.5-4.388l8.958-5.224c1.458-.836 3.333-.836 5 0l8.958 5.433c1.459.836 2.5 2.507 2.5 4.388v6.895l7.917-4.597v-7.104c0-1.672-.833-3.343-2.5-4.388l-16.667-9.821c-1.458-.836-3.333-.836-5 0L26.5 39.448c-1.667.836-2.5 2.507-2.5 4.179v19.642c0 1.671.833 3.343 2.5 4.388l16.875 9.82c1.458.836 3.333.836 5 0l11.458-6.686 7.917-4.597 11.458-6.687c1.459-.835 3.334-.835 5 0l8.959 5.224c1.458.836 2.5 2.508 2.5 4.388v10.448c0 1.672-.834 3.343-2.5 4.388l-8.75 5.224c-1.459.836-3.334.836-5 0l-8.959-5.224c-1.458-.836-2.5-2.507-2.5-4.388v-6.686l-7.916 4.597v6.895c0 1.672.833 3.343 2.5 4.388l16.875 9.821c1.458.836 3.333.836 5 0l16.875-9.82c1.458-.837 2.5-2.508 2.5-4.389v-19.85c0-1.672-.834-3.344-2.5-4.389l-16.875-9.82Z'
        fill='#fff'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M0 0h80v70H0z' fill='#fff' transform='translate(24 29)' />
      </clipPath>
    </defs>
  </Icon>
));

Polygon.displayName = 'Polygon';

export { Polygon };
