import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const Xverse = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#181818' height='48' rx='8' width='48' />
    <g clipPath='url(#clip0_1422_1148)'>
      <path
        d='M34.3893 13.9471V17.7494C34.3893 17.9002 34.3293 18.044 34.2225 18.1508L18.0441 34.3293C17.9373 34.4361 17.7935 34.496 17.6427 34.496H13.8405C13.6528 34.496 13.5 34.3433 13.5 34.1555V30.6229C13.5 30.4721 13.5599 30.3284 13.6667 30.2215L19.4739 24.4143C19.6067 24.2815 19.6067 24.0658 19.4739 23.933L13.5998 18.059C13.5359 17.9951 13.5 17.9082 13.5 17.8183V13.9471C13.5 13.7596 13.6528 13.6066 13.8405 13.6066H20.1938C20.3816 13.6066 20.5343 13.7596 20.5343 13.9471V16.2277C20.5343 16.3176 20.5703 16.4045 20.6342 16.4684L23.7854 19.6196C23.9182 19.7524 24.1338 19.7524 24.2667 19.6196L30.1138 13.7724C30.2206 13.6656 30.3644 13.6056 30.5152 13.6056H34.0478C34.2355 13.6056 34.3883 13.7585 34.3883 13.9461L34.3893 13.9471Z'
        fill='white'
      />
      <path
        d='M25.9142 29.4607H29.0964C29.285 29.4607 29.4388 29.3069 29.4388 29.1182V25.936C29.4388 25.6305 29.8083 25.4777 30.0239 25.6944L34.3893 30.0667C34.4531 30.1307 34.4892 30.2175 34.4892 30.3084V34.1376C34.4892 34.3263 34.3363 34.4801 34.1466 34.4801L30.2605 34.485C30.1697 34.485 30.0829 34.4491 30.0179 34.3852L25.6715 30.0458C25.4559 29.8301 25.6086 29.4607 25.9132 29.4607H25.9142Z'
        fill='#EE7A30'
      />
    </g>
    <defs>
      <clipPath id='clip0_1422_1148'>
        <rect fill='white' height='21' transform='matrix(1 0 0 -1 13.5 34.5)' width='21' />
      </clipPath>
    </defs>
  </Icon>
));

Xverse.displayName = 'Xverse';

export { Xverse };
