import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const OKXWallet = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='35' cy='35' fill='#010203' r='35' />
    <path
      d='M40.4094,28.7584H29.5906a.8127.8127,0,0,0-.8322.8322V40.4094a.8127.8127,0,0,0,.8322.8322H40.4094a.8127.8127,0,0,0,.8322-.8322V29.5906A.8127.8127,0,0,0,40.4094,28.7584Z'
      fill='#fff'
    />
    <path
      d='M27.9262,16.2752H17.1074a.8127.8127,0,0,0-.8322.8322h0V27.9262a.8127.8127,0,0,0,.8322.8322H27.9262a.8127.8127,0,0,0,.8322-.8322h0V17.1074a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M52.8926,16.2752H42.0738a.8127.8127,0,0,0-.8322.8322h0V27.9262a.8127.8127,0,0,0,.8322.8322H52.8926a.8127.8127,0,0,0,.8322-.8322V17.1074a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M27.9262,41.2416H17.1074a.8127.8127,0,0,0-.8322.8322h0V52.8926a.8127.8127,0,0,0,.8322.8322H27.9262a.8127.8127,0,0,0,.8322-.8322h0V42.0738a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M52.8926,41.2416H42.0738a.8127.8127,0,0,0-.8322.8322h0V52.8926a.8127.8127,0,0,0,.8322.8322H52.8926a.8127.8127,0,0,0,.8322-.8322V42.0738a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M40.4094,28.7584H29.5906a.8127.8127,0,0,0-.8322.8322V40.4094a.8127.8127,0,0,0,.8322.8322H40.4094a.8127.8127,0,0,0,.8322-.8322V29.5906A.8127.8127,0,0,0,40.4094,28.7584Z'
      fill='#fff'
    />
    <path
      d='M27.9262,16.2752H17.1074a.8127.8127,0,0,0-.8322.8322h0V27.9262a.8127.8127,0,0,0,.8322.8322H27.9262a.8127.8127,0,0,0,.8322-.8322h0V17.1074a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M52.8926,16.2752H42.0738a.8127.8127,0,0,0-.8322.8322h0V27.9262a.8127.8127,0,0,0,.8322.8322H52.8926a.8127.8127,0,0,0,.8322-.8322V17.1074a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M27.9262,41.2416H17.1074a.8127.8127,0,0,0-.8322.8322h0V52.8926a.8127.8127,0,0,0,.8322.8322H27.9262a.8127.8127,0,0,0,.8322-.8322h0V42.0738a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
    <path
      d='M52.8926,41.2416H42.0738a.8127.8127,0,0,0-.8322.8322h0V52.8926a.8127.8127,0,0,0,.8322.8322H52.8926a.8127.8127,0,0,0,.8322-.8322V42.0738a.8127.8127,0,0,0-.8322-.8322Z'
      fill='#fff'
    />
  </Icon>
));

OKXWallet.displayName = 'OKXWallet';

export { OKXWallet };
