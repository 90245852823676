import { forwardRef } from 'react';
import { Icon, IconProps } from '@gobob/ui';

const LeatherWallet = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect fill='#12100F' height='48' rx='8' width='48' />
    <path
      d='M27.462 20.783C29.5965 20.4425 32.6833 18.1276 32.6833 16.3234C32.6833 15.7787 32.2564 15.4043 31.6325 15.4043C30.4503 15.4043 28.4472 17.2425 27.462 20.783ZM17.5777 29.7361C14.7864 29.7361 14.5566 32.5957 17.3478 32.5957C18.5957 32.5957 20.1063 32.085 20.8944 31.1659C19.745 30.1446 18.7927 29.7361 17.5777 29.7361ZM35.3432 28.5106C35.5073 33.3106 33.1431 36 29.1368 36C26.7724 36 25.5903 35.0808 23.0617 33.3787C21.7482 34.8766 19.2525 36 17.1836 36C10.0577 36 10.3533 26.6383 17.6105 26.6383C19.1211 26.6383 20.4018 27.0468 22.0437 28.1021L23.1274 24.1872C18.6614 22.9276 16.4283 19.3872 18.6285 14.3149H22.1751C20.2048 17.6851 21.5511 20.4766 24.0468 20.783C25.3932 15.8468 28.283 12 32.1251 12C34.2924 12 36 13.4638 36 16.1191C36 20.3744 30.6145 23.8468 26.5425 24.1872L24.8678 30.2808C26.7724 32.5616 32.0594 34.7743 32.0594 28.5106H35.3432Z'
      fill='#F5F1ED'
    />
  </Icon>
));

LeatherWallet.displayName = 'LeatherWallet';

export { LeatherWallet };
